import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'

const Treatment = () => {
  return (
    <div id="treatments" className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100">
          <div className="col-md-6 background-image background-treatment"></div>
          <div className="col-md-6"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6">
            <div className="spacer-background-image"></div>
          </section>
          <section className="col-md-6 p-5">
            <h2>Treatments</h2>
            <h4>The ultimate pamper session for you and your hair!</h4>
            <p>
              Hair Analysis- Choosing the perfect treatment for you is
              important, as everyone’s hair needs are different. Let me know
              your hair concerns and we can find the treatment that’s perfect
              for you and your hair.
            </p>
            <p>
              Basin- Sit back and relax with dimmed lights as our beautiful
              massage chair reclines. Select the massage setting you desire
              while I hand select the perfect shampoo for your hair.
            </p>
            <p>
              Porosity Equaliser–To improve treatment penetration & retention.
              Reconstructs & evens prorsity. (Quinoa & Vegetable proteins,
              Aminodew, Chia, Sunflower & Swiss grape Seed Extract)
            </p>
            <p>
              Treatment- Your specialised treatment hand picked for you can
              process at the basin with hot towels or enjoy your favourite drink
              and treat back at your chair under our custom heat butterfly.
            </p>
            <p>
              Massage- After relaxing and letting your treatment revitalise your
              hair, indulge in my signature head massage to give that treatment
              the extra kick it needs to go above and beyond for your hair.
              Enjoy an extra neck massage to top of your pamper session.
            </p>
            <p>
              Finish- Choose your desired finishing service from Style cut to
              Blowdry. Walk out on cloud 9, ready for anything!
            </p>
            <a
              href={BOOKING_LINK}
              rel="noreferrer"
              target="_blank"
              className="links"
            >
              Treat Yourself!
            </a>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Treatment
